import Head from 'next/head';
import Router from 'next/router';
import { useEffect } from 'react';
import App from 'next/app';
import datasource from '../datalayer';
import { SessionProvider } from 'next-auth/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { CloudProvider } from 'context/CloudContext';
import { ProductSlugProvider } from 'context/ProductSlugContext';
import NProgress from 'nprogress';
import { LimitSavedDesignProvider } from 'context/LimitSavedDesignContext';
import { AuthProvider } from 'context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';
import { MountPoint } from 'utils/reactConfirm';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '../styles/font-awesome.min.css';
import '../styles/sass/styles.scss';
import { SubscriptionProvider } from 'context/SubscriptionContext';
import { SiteInfoProvider } from '@/context/SiteInfoContext';
import { CategoryProvider } from '@/context/CategoryContext';
import { FilterProvider } from '@/context/FilterContext';
import cookie from 'cookie';
import jwt from 'jsonwebtoken';
import { API_SECRET } from '@/constants/privateConstants';

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
});

const queryClient = new QueryClient();

function MyApp({ Component, pageProps, session }) {
  const getLayout = Component.getLayout || ((props, page) => page);

  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', (url) => {
      NProgress.done(false);
    });

    Router.events.on('routeChangeError', (url) => {
      NProgress.done(false);
    });
  }, []);

  return (
    <>
      <Head>
        <meta name="robots" content="index" />
        <meta name="googlebot" content="index" />
      </Head>
      <ToastContainer
        position={'top-center'}
        closeOnClick={true}
        draggable={true}
      />
      <ProductSlugProvider>
        <CloudProvider>
          <SiteInfoProvider initialSiteInfo={pageProps.siteInfo}>
            <CategoryProvider>
              <LimitSavedDesignProvider>
                <QueryClientProvider client={queryClient}>
                  <SessionProvider session={session}>
                    <SubscriptionProvider>
                      <ChakraProvider theme={theme}>
                        <MountPoint />
                        <AuthProvider>
                          <FilterProvider initialProps={pageProps}>
                            {getLayout(pageProps, <Component {...pageProps} />)}
                          </FilterProvider>
                        </AuthProvider>
                      </ChakraProvider>
                    </SubscriptionProvider>
                  </SessionProvider>
                </QueryClientProvider>
              </LimitSavedDesignProvider>
            </CategoryProvider>
          </SiteInfoProvider>
        </CloudProvider>
      </ProductSlugProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  const isServer = typeof window === 'undefined';
  var domain = '';
  // If on server, get domain from request headers
  if (isServer) {
    const token = jwt.sign(
      { count: 50 }, // Payload
      API_SECRET, // Secret key
      { expiresIn: '15m' }, // Expiration time: 5 minutes
    );

    // Set the JWT as a cookie with additional settings
    appContext.ctx.res.setHeader(
      'Set-Cookie',
      cookie.serialize('token', token, {
        httpOnly: true, // Prevent JavaScript access to the cookie
        secure: process.env.NODE_ENV !== 'development', // Use Secure in production (https only)
        maxAge: 60 * 15, // 5 minutes in seconds
        sameSite: 'strict', // Prevent CSRF (strict policy)
        path: '/', // Cookie is accessible on all pages
      }),
    );

    domain = appContext.ctx.req.headers.host;
  } else {
    domain = window.location.hostname;
  }
  if (domain.startsWith('www.')) {
    domain = domain.substring(4);
  }

  const [siteInfo] = await Promise.all([datasource.getSiteInfo(domain)]);

  return { ...appProps, pageProps: { domain, siteInfo } };
};

export default MyApp;
